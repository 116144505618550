import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { TokenService } from './auth/services/token.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'frontend-QSM';

  constructor(
    private authService: MsalService,
    private tokenService: TokenService
  ) {}

  ngOnInit() {
    this.authService.initialize();
    this.tokenService.getAccount();
  }
}
