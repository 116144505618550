import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { MsalService } from '@azure/msal-angular';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);

  const addToken = (req: HttpRequest<any>, token: string | null) => {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const authToken = tokenService.token;
  let authReq = addToken(req, authToken);

  return next(authReq);
};
