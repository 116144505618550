import { Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { RoleGuard } from './auth/guards/role.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () =>
      import('./pages/layout/layout.component').then((c) => c.LayoutComponent),
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ['GeneralUsers', 'Admin'] },
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./pages/home/home.component').then((c) => c.HomeComponent),
      },
      {
        path: 'portfolio',
        loadComponent: () =>
          import('./pages/portfolio/portfolio.component').then(
            (c) => c.PortfolioComponent
          ),
      },
      {
        path: 'performance',
        loadComponent: () =>
          import('./pages/performance/performance.component').then(
            (c) => c.PerformanceComponent
          ),
      },
      {
        path: 'contribution',
        loadComponent: () =>
          import('./pages/contribution/contribution.component').then(
            (c) => c.ContributionComponent
          ),
      },
    ],
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./pages/access-denied/access-denied.component').then(
        (c) => c.AccessDeniedComponent
      ),
  },
  {
    path: 'legal-notice',
    loadComponent: () =>
      import('./pages/legal-notice/legal-notice.component').then(
        (c) => c.LegalNoticeComponent
      ),
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () =>
      import(
        './pages/terms-and-conditions/terms-and-conditions.component'
      ).then((c) => c.TermsAndConditionsComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },

  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
